import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VSpacer)],1),_c(VCardText,[_c(VRow,[_c(VCol,{staticClass:"d-flex",attrs:{"cols":"6","sm":"3"}},[_c('div',{staticClass:"text-center mr-3"},[_c(VSelect,{attrs:{"items":_vm.veterinary_items,"item-value":"id","item-text":"name","dense":"","label":"Filtrar por usuario"},on:{"change":function($event){return _vm.getPayments()}},model:{value:(_vm.filter.vet),callback:function ($$v) {_vm.$set(_vm.filter, "vet", $$v)},expression:"filter.vet"}})],1),(_vm.filter.vet)?_c(VTooltip,{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.filter.vet = null; _vm.getPayments()}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)]}}],null,false,2146329710)},[_c('span',[_vm._v("Eliminar filtro")])]):_vm._e()],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.paymentsListTable,"page":_vm.input_search && _vm.input_search.length? _vm.current_page_with_search : _vm.current_page,"server-items-length":_vm.listTableCount,"loading":_vm.loading,"footer-props":{ 'items-per-page-options': [5, 10, 15], 'items-per-page-text': 'Items por página' }},on:{"update:page":function($event){return _vm.changePaymentPage($event)},"update:items-per-page":function($event){return _vm.changeItemsPerPage($event)}},scopedSlots:_vm._u([{key:"item.request",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"sku-container"},[_vm._v(_vm._s(item.client['sku']))])]}},{key:"item.payment_method",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"date-container"},[_vm._v(_vm._s(item.payment_method.label))])]}},{key:"item.reference",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"date-container"},[_vm._v(_vm._s(item.reference && item.reference.length? item.reference : '-'))])]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","disabled":(!item.proof_of_payment && !item.proof_of_payment.length)},on:{"click":function($event){return _vm.downloadPdf(item.proof_of_payment)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDownloadOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Descargar comprobante de pago")])]),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.sharePDF(item)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":"info"}},[_vm._v(" "+_vm._s(_vm.icons.mdiShareVariantOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Compartir comprobante de pago")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"elevation":"2","x-small":"","outlined":""},on:{"click":function($event){return _vm.$refs.patient_files_modal.openModal(item.id)}}},[_vm._v(" Ver Archivos ")])]}},{key:"item.hidden",fn:function(ref){
var item = ref.item;
return [_c(VMenu,{attrs:{"offset-x":"","right":"","disabled":_vm.getUserRole.id !== 5},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"elevation":"2","small":"","color":item.hidden ? 'success' : 'error',"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" "+_vm._s(item.hidden ? _vm.icons.mdiCheckAll : _vm.icons.mdiClose)+" ")])],1)]}}],null,true)},[_c(VCard,{attrs:{"elevation":"8"}},[_c(VCardSubtitle,[_c('b',[_vm._v(" "+_vm._s(("¿Estás seguro de " + (item.hidden ? 'mostrar' : 'ocultar') + " este pago?")))])]),_c(VCardText,{staticClass:"text-center"},[_c(VBtn,{staticClass:"me-3",attrs:{"small":"","outlined":"","color":"error"}},[_vm._v(_vm._s('Cancelar'))]),_c(VBtn,{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.hiddePayment(item.id)}}},[_vm._v(_vm._s('Confirmar'))])],1)],1)],1)]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.input_search.length ? 'No hay pagos que coincidan con la búsqueda' : 'No hay pagos registrados aún')+" ")]},proxy:true}],null,true)})],1),_c('FilesModal',{ref:"patient_files_modal"}),_c('ShareModal',{ref:"share_modal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }