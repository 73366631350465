import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"800","persistent":""},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_c(VIcon,{staticClass:"me-2"},[_vm._v(" "+_vm._s(_vm.icons.mdiSendOutline))]),_vm._v(" "+_vm._s(this.element_type === "invoice" ? "¿Enviar presupuesto?" : "¿Enviar comprobante de pago?")+" ")],1),(!_vm.requestless)?_c('div',[_c(VCardSubtitle,{staticClass:"pt-3"},[_vm._v(" Tilde los email que recibiran una copia del comprobante:")]),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.contactList,"item-key":"label","hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c(VListItem,[_c(VListItemTitle,[_vm._v(" No se encontraron contactos asociados a la solicitud "),_c('div',[_c('small',{staticClass:"font-italic"},[_vm._v("revise que el cliente no haya sido eliminado de registros")])])])],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('small',[_vm._v(_vm._s(item.label))])]}},{key:"header.email",fn:function(ref){
var header = ref.header;
return [_c(VIcon,{staticClass:"me-2"},[_vm._v(_vm._s(_vm.icons.mdiEmailOutline))]),_vm._v(" "+_vm._s(header.text.toUpperCase())+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex my-auto"},[_c(VCheckbox,{attrs:{"dense":"","disabled":!item.email},model:{value:(item.selectedEmail),callback:function ($$v) {_vm.$set(item, "selectedEmail", $$v)},expression:"item.selectedEmail"}}),_c('span',{staticClass:"my-2"},[_vm._v(" "+_vm._s(item.email || "sin registro")+" ")])],1)]}}],null,true)}),(_vm.element_type === 'invoice')?_c(VDivider):_vm._e()],1):_c('div',[_c(VCardSubtitle,{staticClass:"pt-3"},[(_vm.requestlessClient && _vm.requestlessClient.email)?_c('span',[_vm._v(" Email registrado para el cliente: "+_vm._s(_vm.requestlessClient.email)+" ")]):_c('span',[_vm._v(" El cliente no tiene un email registrado. Edite el usuario o tutor correspondiente en la seccion 'Registros' de la app. ")])])],1),(_vm.element_type === 'invoice')?_c(VExpansionPanels,{attrs:{"flat":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"ps-0"},[_c(VCardTitle,[_vm._v(" Datos para el pago ")])],1),_c(VExpansionPanelContent,[_c(VTextarea,{attrs:{"rows":"11","outlined":"","dense":"","autocomplete":"off","hide-details":"auto"},model:{value:(_vm.payment_data),callback:function ($$v) {_vm.payment_data=$$v},expression:"payment_data"}})],1)],1)],1):_vm._e(),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","outlined":"","disabled":_vm.loading},on:{"click":function($event){return _vm.close()}}},[_vm._v(" CANCELAR ")]),_c(VBtn,{attrs:{"color":"success","disabled":_vm.loading},on:{"click":_vm.sendPDF}},[_vm._v(" ENVIAR ")])],1)],1)],1),_c('alert-pop-up',{ref:"alert_pop_up"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }