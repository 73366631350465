<template>
  <div>
    <v-dialog v-model="showDialog" max-width="800" persistent>
      <v-card :loading="loading">
        <v-card-title class="text-h5 grey lighten-2">
          <v-icon class="me-2"> {{ icons.mdiSendOutline }}</v-icon>
          {{
            this.element_type === "invoice"
              ? "¿Enviar presupuesto?"
              : "¿Enviar comprobante de pago?"
          }}
        </v-card-title>

        <div v-if="!requestless">
          <v-card-subtitle class="pt-3">
            Tilde los email que recibiran una copia del comprobante:</v-card-subtitle
          >

          <v-data-table
            :headers="headers"
            :items="contactList"
            item-key="label"
            hide-default-footer
          >
            <!-- NO data -->
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  No se encontraron contactos asociados a la solicitud
                  <div><small class="font-italic">revise que el cliente no haya sido eliminado de registros</small></div>
                </v-list-item-title>
              </v-list-item>
            </template>
            <!-- Nombre -->
            <template #[`item.name`]="{item}">
              {{ item.name }}
            </template>

            <template v-slot:item.name="{ item }">
              <span class="d-block font-weight-semibold text--primary text-truncate">
                {{ item.name }}
              </span>
              <small>{{ item.label }}</small>
            </template>

            <!-- EMAIL -->
            <template v-slot:header.email="{ header }">
              <v-icon class="me-2">{{ icons.mdiEmailOutline }}</v-icon>
              {{ header.text.toUpperCase() }}
            </template>
            <template v-slot:item.email="{ item }">
              <div class="d-flex my-auto">
                <v-checkbox
                  class=""
                  dense
                  :disabled="!item.email"
                  v-model="item.selectedEmail"
                >
                </v-checkbox>
                <span class="my-2">
                  {{ item.email || "sin registro" }}
                </span>
              </div>
            </template>

            <!-- PHONE -->
            <!-- <template v-slot:header.phone="{ header }">
                <v-icon class="me-2">{{ icons.mdiWhatsapp }}</v-icon>
                {{ header.text.toUpperCase() }}
              </template>
              <template v-slot:item.phone="{ item }">
                <div class="d-flex my-auto">
                  <v-checkbox
                    class=""
                    dense
                    :disabled="!item.phone"
                    v-model="item.selectedPhone"
                  >
                  </v-checkbox>
                  <span class="my-2">
                    {{ item.phone || 'sin registro'}}
                  </span>
                </div>
              </template> -->
          </v-data-table>
          <v-divider v-if="element_type === 'invoice'"></v-divider>
        </div>
        <div v-else>
          <v-card-subtitle class="pt-3">
            <span v-if="requestlessClient && requestlessClient.email">
              Email registrado para el cliente: {{ requestlessClient.email }}
            </span>
            <span v-else>
              El cliente no tiene un email registrado. Edite el usuario o tutor correspondiente en la seccion 'Registros' de la app.
            </span>
          </v-card-subtitle>
        </div>

        <v-expansion-panels v-if="element_type === 'invoice'" flat v-model="panel" >
          <v-expansion-panel>
            <v-expansion-panel-header class="ps-0">
            <v-card-title>
              Datos para el pago
            </v-card-title>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-textarea 
              v-model="payment_data"
              rows="11"
              outlined
              dense
              autocomplete="off"
              hide-details="auto"
            />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error"
            outlined
            :disabled="loading"
            @click="close()"
            >
              CANCELAR
            </v-btn>         
          <v-btn color="success" :disabled="loading" @click="sendPDF">
            ENVIAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <alert-pop-up ref="alert_pop_up" />
  </div>
</template>

<script>
import AlertPopUp from "@/components/AlertPopUp.vue";
import { mdiClose, mdiSendOutline, mdiEmailOutline, mdiWhatsapp } from "@mdi/js";

export default {
  data: () => {
    return {
      showDialog: false,
      requestless: false,
      contactList: [],
      requestlessClient: {},
      element_id: null,
      element_type: null,
      loading: false,
      headers: [
        { text: "", align: "left", sortable: false, value: "name" },
        { text: "MAIL", align: "left", sortable: false, value: "email" },
        // { text: 'WHATSAPP', align: 'left', sortable: false, value: 'phone'},
      ],
      payment_data: '',
      panel: null,
    };
  },
  created() {
    this.payment_data = `Efectivo:\nDirección: Arenales 1015 Oeste – Capital. Puede consultar nuestra ubicación en www.iluminarlaboratorio.com.ar \nTransferencia bancaria:\nCBU: 0110474930047441572979\nALIAS: iluminar.laboratorio\nBanco Nación\nTitular: Sosa Natalia \nCUIT: 27-35473202-0\nMercadopago:\nOpción transferir dinero\nAlias: Iluminar.lab`;
  },
  methods: {
    openSendModal(requestless, contacts, element) {
      this.payment_data = `Efectivo:\nDirección: Arenales 1015 Oeste – Capital. Puede consultar nuestra ubicación en www.iluminarlaboratorio.com.ar \nTransferencia bancaria:\nCBU: 0110474930047441572979\nALIAS: iluminar.laboratorio\nBanco Nación\nTitular: Sosa Natalia \nCUIT: 27-35473202-0\nMercadopago:\nOpción transferir dinero\nAlias: Iluminar.lab`;
      this.showDialog = true;
      this.requestless = requestless;

      requestless ? this.requestlessClient = contacts : this.contactList = contacts

      this.element_id = element.id;
      this.element_type = element.type;

    },
    async sendPDF() {
      this.loading = true;
      const form = {
        veterinaries: [],
        tutors: [],
        veterinary_clinics: [],
        clients: []
      };

      if(this.element_type === 'invoice') {
        const paymentData = this.payment_data.split('\n');
        form.payment_methods = paymentData
      }

      if(this.requestless) {
        const client = {...this.requestlessClient}
        delete client.type

        if(this.requestlessClient.type === 'userdata'){
          form.veterinaries.push(client)
        }

        if(this.requestlessClient.type === 'tutor'){
          form.tutors.push(client)
        }
 
        if(this.requestlessClient.type === 'other'){
          delete client.id
          form.clients.push(client)
        }
      } else {
        this.contactList.forEach((contact) => {
          if (contact.selectedEmail || contact.selectedPhone) {
            const element = {
              id: contact.id,
              name: contact.name,
              email: contact.selectedEmail ? contact.email : "",
              // phone: contact.selectedPhone ? contact.phone : '',
            };
            switch (contact.label) {
              case "Veterinario":
                form.veterinaries.push(element);
                break;
              case "Tutor":
                form.tutors.push(element);
                break;
              case "Clínica":
                form.veterinary_clinics.push(element);
                break;
              default:
                break;
            }
          }
        });
      }


      try {
        // return console.log(this.element_id, form, this.element_type);
        await this.$api.sharePdfElement(this.element_id, form, this.element_type);

        // console.log(response);
        this.$refs.alert_pop_up.displayAlert({
          message: `${
            this.element_type === "invoice" ? "Presupuesto" : "Comprobante"
          } enviado correctamente`,
          type: "info",
        });
      } catch (error) {
        this.$refs.alert_pop_up.displayAlert({
          message: "Ha ocurrido un error",
          type: "error",
        });
      } finally {
        this.loading = false;
        this.close()
      }
    },
    close(){
      this.showDialog = false
      this.requestless = false
      this.contactList = []
      this.requestlessClient = {}
      this.element_id = null 
      this.element_type = null
      this.panel = null
    }
  },
  setup() {
    return {
      icons: {
        mdiClose,
        mdiSendOutline,
        mdiEmailOutline,
        mdiWhatsapp,
      },
    };
  },
  components: {
    AlertPopUp,
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>
