<template>
  <div>
    <v-card>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- Search -->
        <!-- <v-text-field
          :append-icon="icons.mdiMagnify"
          placeholder="Buscar pago"
          v-model="input_search"
          outlined
          hide-details
          dense
          class="me-3 mb-4"
        /> -->

        <v-spacer></v-spacer>
      </v-card-text>

      <v-card-text>

        <v-row>
          <v-col cols="6" sm="3" class="d-flex">
            <div class="text-center mr-3">
              <v-select
                :items="veterinary_items"
                item-value="id"
                item-text="name"
                @change="getPayments()"
                dense
                label="Filtrar por usuario"
                v-model="filter.vet"
              ></v-select>
            </div>

            <v-tooltip right v-if="filter.vet">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="filter.vet = null; getPayments()"
                >
                  <v-icon
                    color="error"
                  >
                    {{ icons.mdiClose }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar filtro</span>
            </v-tooltip>
          </v-col>

        </v-row>
      </v-card-text>

      <v-data-table
        :headers="tableColumns"
        :items="paymentsListTable"
        :page="input_search && input_search.length? current_page_with_search : current_page"
        :server-items-length="listTableCount"
        :loading="loading"
        :footer-props="{ 'items-per-page-options': [5, 10, 15], 'items-per-page-text': 'Items por página' }"
        @update:page="changePaymentPage($event)"
        @update:items-per-page="changeItemsPerPage($event)"
      >
        <template #[`item.request`]="{item}">
          <span class="sku-container">{{ item.client['sku'] }}</span>
        </template>

        <template #[`item.payment_method`]="{item}">
          <span class="date-container">{{ item.payment_method.label }}</span>
        </template>

        <template #[`item.reference`]="{item}">
          <span class="date-container">{{ item.reference && item.reference.length? item.reference : '-' }}</span>
        </template>

        <template #[`item.download`]="{item}">

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                :disabled="(!item.proof_of_payment && !item.proof_of_payment.length)"
                @click="downloadPdf(item.proof_of_payment)"
              >
              <v-icon
                color="success"
              >
                {{ icons.mdiDownloadOutline }}
              </v-icon>
              </v-btn>
            </template>
            <span>Descargar comprobante de pago</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="sharePDF(item)"
              >
              <v-icon
                color="info"
              >
                {{ icons.mdiShareVariantOutline }}
              </v-icon>
              </v-btn>
            </template>
            <span>Compartir comprobante de pago</span>
          </v-tooltip>

        </template>

        <template #[`item.actions`]="{item}">
          <v-btn
            elevation="2"
            x-small
            outlined
            @click="$refs.patient_files_modal.openModal(item.id)"
          >
            <!-- @click="openPaymentModal(item.id)" -->
            Ver Archivos
          </v-btn>
        </template>

        <template #[`item.hidden`]="{ item }">
          <v-menu offset-x right :disabled="getUserRole.id !== 5">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                elevation="2"
                small
                :color="item.hidden ? 'success' : 'error'"
                icon
              >
                <v-icon>
                  {{ item.hidden ? icons.mdiCheckAll : icons.mdiClose }}
                </v-icon>
              </v-btn>
            </template>
            <v-card elevation="8">
              <v-card-subtitle> <b> {{ `¿Estás seguro de ${item.hidden ? 'mostrar' : 'ocultar'} este pago?`  }}</b></v-card-subtitle>
              <v-card-text class="text-center">
                <v-btn small outlined color="error" class="me-3">{{'Cancelar' }}</v-btn>
                <v-btn small color="primary" @click="hiddePayment(item.id)">{{ 'Confirmar'}}</v-btn>
              </v-card-text>
            </v-card>
          </v-menu>
          <!-- <v-btn
            elevation="2"
            small
            :color="item.hidden ? 'success' : 'error'"
            icon
            @click="hiddePayment(item.id)"
          >
            <v-icon>
              {{ item.hidden ? icons.mdiCheckAll : icons.mdiClose}}
            </v-icon>
          </v-btn> -->

        </template>

        <!-- no-data -->
        <template v-slot:no-data>
          {{
            input_search.length
              ? 'No hay pagos que coincidan con la búsqueda'
              : 'No hay pagos registrados aún'
          }}
        </template>
      </v-data-table>

    </v-card>

    <FilesModal
      ref="patient_files_modal"
    />

    <ShareModal ref="share_modal" />

  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import debounce from '../../plugins/debounce.js'
import FilesModal from './FilesModal.vue';
import ShareModal from '@/components/share-modal/ShareModal.vue';
import {
  mdiMagnify,
  mdiClose,
  mdiDownloadOutline,
  mdiShareVariantOutline,
  mdiCheckAll
} from '@mdi/js'

export default {
  data: () => {
    return {
      current_page: 1,
      current_page_with_search: 1,
      page_size: 10,

      filter: { vet: null },
      input_search: '',
      veterinary_items: [],
    }
  },
  methods: {

    async sharePDF(item) {

      if (item.client?.type === "request") {
        try {
          const contacts = await this.getContacts(item.client.id);
          const requestless = false;

          this.$refs.share_modal.openSendModal(requestless, contacts, {
            id: item.id,
            type: 'payment'
          })
        } catch (error) {
          console.log(error);
        }
      } else {

        const contacts = { email: item.client.email, name: item.client.name, id: item.client.id ?? null, type: item.client.type };
        const requestless = true;

        this.$refs.share_modal.openSendModal(requestless, contacts, {
          id: item.id,
          type: 'payment',
        });
      }
    },

    async getContacts(request_id) {
      const { data } = await this.callGetOneRequest(request_id)
      const contacts = []
      const contacts_container_raw = [data.userdata, data.veterinary_clinic, data.tutor]

      contacts_container_raw.forEach((contact, idx) => {
        if (contact && Object.keys(contact).length) {
          contacts.push({
            id: contact.id,
            label: idx === 0? 'Veterinario' : idx === 1? 'Clínica' : 'Tutor',
            name: contact.name,
            email: contact.email,
            phone: contact.phone
          })
        }
      });

      return contacts
    },

    async callGetOneRequest(id) {
      try {
        const response = await this.$api.getRequest(id)
        return response
      } catch(e) {
        console.log(e)
        return false
      }
    },


    async getPayments() {
      this.loading = true

      try {

        const response = await this.$api.getPayments(this.getQueryParams)

        this.paymentsListTable = response.results
        this.listTableCount = response.count
      } catch(e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    async getVeterinaryUsers() {
      try {
        const response = await this.$api.getUsersList({ role: 0, summary: true })
        this.veterinary_items = response
      } catch (error) {
        console.log(error);
      }
    },

    downloadPdf(url){
      window.open(url, '_blank')
    },

    changePaymentPage(e) {
      this.current_page = e
      this.getPayments()
    },

    changeItemsPerPage(e) {
      this.page_size = e
      this.getPayments()
    },

    async hiddePayment(id) {
      try {
        const response = await this.$api.hiddePayment(id)
        this.getPayments()
      } catch (error) {
        console.log(error);
      }
    }
  },
  mounted() {
    this.getPayments(),
    this.getVeterinaryUsers()
  },
  components: {
    FilesModal,
    ShareModal,
  },

  computed: {
    getUserRole() { return this.$store.getters['session/getUserRole']() },
    getQueryParams() {
      const filter = {
        page_size: this.page_size,
        page: this.current_page,
      }

      if (this.input_search.length) {
        filter['search'] = this.input_search
        filter['page'] = this.current_page_with_search
      }
      if (this.filter.vet) filter['userdata'] = this.filter.vet

      return filter
    }
  },

  watch: {
    // deprecated searchs
    // input_search(newValue, oldValue) {
    //   if (!oldValue) {
    //     this.current_page_with_search = 1
    //   }
    //   this.debouncedSearch()
    // }
  },

  setup() {
    const listTableCount = ref(0);
    const paymentsListTable = ref([]);

    const tableColumns = [
      { text: 'FECHA', value: 'date', align: 'center', sortable: false, width: 200 },
      { text: 'SKU SOLICITUD', value: 'request', align: 'center', sortable: false },
      { text: 'MET. DE PAGO', value: 'payment_method', align: 'center', sortable: false },
      { text: 'OBSERVACIONES', value: 'reference', align: 'center', sortable: false },
      { text: '- ACCIONES -', value: 'download', align: 'center', sortable: false, width: 120 },
      { text: 'ARCHIVOS', value: 'actions', align: 'center', sortable: false },
      { text: 'OCULTO', value: 'hidden', align: 'center', sortable: false },

    ]

    const loading = ref(false);

    return {
      loading,
      listTableCount,
      paymentsListTable,
      tableColumns,
      icons: {
        mdiMagnify,
        mdiClose,
        mdiDownloadOutline,
        mdiShareVariantOutline,
        mdiCheckAll
      }
    }
  },
}
</script>

<style scoped>
.date-container {
  font-weight: 500;
  color: rgb(125, 125, 125);
}

.sku-container {
  font-weight: 600;
  color: rgb(115, 115, 115);
}
</style>
