<template>
  <div>
    <v-card>
      <v-card-title>Pagos</v-card-title>
      <PaymentList />
    </v-card>
  </div>
</template>

<script>
import PaymentList from '@/components/payment/PaymentList';
  export default {
    components:{
      PaymentList
    }
    
  }
</script>