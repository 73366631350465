<template>
  <div>
    <v-dialog
      v-model="showFilesDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          ARCHIVOS
        </v-card-title>

        <v-list rounded v-if="files.length">

          <v-list-item
            v-for="(file, i) in files"
            class="d-flex align-center"
            :key="i"
          >
            <v-list-item-icon class="mt-5">
              <v-icon v-text="icons.mdiFileMultipleOutline"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="file.name"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon @click="downloadFile(file.url)">
                <v-icon v-text="icons.mdiDownload"></v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>


        </v-list>

        <v-card-text v-else>
          No hay archivos asociados al pago
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="text-end">
          <v-btn 
            color="primary"
            @click="showFilesDialog = !showFilesDialog"
          >
            Cerrar
          </v-btn>
          
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="showAlert"
      :light="$vuetify.theme.dark"
      :timeout="1000"
      centered
    >
    {{ responseMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import {
  mdiFileMultipleOutline,
  mdiDelete,
  mdiDownload
} from '@mdi/js'
 
export default {
  data() {
    return {
      showFilesDialog: false,
      payment_id: -1,
      files: [],
      showAlert: false,
      responseMessage: ''
    }
  },
  methods: {
    async openModal(payment_id) {
      this.showFilesDialog = true
      this.payment_id = payment_id
      
      await this.getFiles()
      
    },
    
    
    async getFiles() {
      try {
        const response = await this.$api.getPaymentFiles(this.payment_id)
        this.files = response.data.files
      } catch(error) {
        console.log(error)
      }
    },
    
    downloadFile(url){
      window.open(url, '_blank')
    },
    
  },
  setup() {
    
    return {
      icons:{
        mdiFileMultipleOutline,
        mdiDelete,
        mdiDownload
      }
    }
  }
}
</script>